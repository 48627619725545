import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getSiteSetting } from "../../api/users";

// import lib
import isLogin from '../../lib/isLogin';
import isEmpty from "../../lib/isEmpty";

const ConditionRoute = ({ type, children, ...rest }) => {

   const siteDoc = useSelector((state) => state.siteSetting)


   const currentLocation = useLocation();
   const [restrictData, setresData] = useState(true);
   const dispatch = useDispatch()
   // const [siteDoc, setSiteDoc] = useState({})



   // const fetchData = async () => {
   //    let { status, result } = await getSiteSetting(dispatch);
   //    if (status == "success") {
   //       setSiteDoc(result);
   //    }
   // };


   // useEffect(() => {
   //    if (siteDoc) {
   //       if (currentLocation.pathname == "/trade" && !siteDoc.tradeAccess) {
   //          setresData(false);
   //       } else if (currentLocation.pathname == "/staking" && !siteDoc.stakeAccess) {
   //          setresData(false);
   //       } else if (currentLocation.pathname == "/wallet" && !siteDoc.walletAccess) {
   //          setresData(false);
   //       } else if (currentLocation.pathname == "/transaction-history" && !siteDoc.transactionAccess) {
   //          setresData(false);
   //       } else if (currentLocation.pathname == "/hedging" && !siteDoc.hedgeAccess) {
   //          setresData(false);
   //       } else if (currentLocation.pathname == "/stakinghistory" && !siteDoc.historyAccess) {
   //          setresData(false);
   //       } else if (currentLocation.pathname == "/live-events" && !siteDoc.liveEventAccess) {
   //          setresData(false);
   //       }
   //    }


   // }, [siteDoc])

   // useEffect(() => {
   //    fetchData()
   // }, [])


   // console.log(siteDoc, "-siteDocsiteDoc", currentLocation, restrictData)

   if (type == 'auth' && isLogin() === true) {
      return <Navigate to='/' />
   } else if (type == 'private' && isLogin() !== true) {
      return <Navigate to='/login' />
   } else if (restrictData == false) {
      return <Navigate to='/' />
   }

   return children;
};

export default ConditionRoute;