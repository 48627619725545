// import config
import axios, { handleResp } from '../config/axios'

// import constant
import {
    SET_USER_WALLET_LIST,SET_CURRENCY,SET_PRICE_CONVERSION, SET_AVAILABLE_BALANCE,
    // SET_USER_FIRST_CURRENCY,
    // SET_USER_SECOND_CURRENCY,
    // UPDATE_USER_WALLET_STAKE
} from '../constant';

export const getAssetData = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/getAssetsDetails`,
        });
        dispatch(userWalletList(respData?.data?.result?.assets))
        return true
    }
    catch (err) {
        handleResp(err, 'error')
        return false
    }
}

export const userWalletList = (data) => {
    // console.log(data,'datadatadata')

    return {
        type: SET_USER_WALLET_LIST,
        data
    }
}

export const getArbiProfit = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/getArbiProfit`,
        });
        console.log(respData?.data,'-respData?.data')
        dispatch(setAvailableBal({ profit: respData?.data?.result}))
        return true
    }
    catch (err) {
        handleResp(err, 'error')
        return false
    }
}


export const setAvailableBal = (data) => {
    // console.log(data,'datadatadata')

    return {
        type: SET_AVAILABLE_BALANCE,
        data
    }
}

// export const setAvailableBal = (
//     {
//         totalBal,
//         lockedBal,
//         USDTBal,
//     },
//     dispatch
// ) => {
//     dispatch({
//         type: SET_AVAILABLE_BALANCE,
//         data: {
//             totalBal,
//             lockedBal,
//             USDTBal,
//         },
//     });

//     return true;
// };

export const coinRequestVerify = async (data) => {
    try {
        let respData = await axios({
            'method': 'patch',
            'url': `/api/user/coinWithdraw`,
            'data': data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const transferCurrency = async ( data,dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/transfer`,
            'data': data
        });
        getAssetData(dispatch)
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const WithdrawReq = async (data,dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/withdrawrequest`,
            'data': data
        });
        getAssetData(dispatch)
        if(respData?.data?.status === "OTP") {
            return {
                status: "OTP",
                loading: false,
                message: respData.data.message,
            }
        }
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors,
        }
    }
}

export const getTrnxHistory = async (params) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/transaction-history`,
            params
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}




export const getCurrency = async (dispatch) => {
    try {
      let respData = await axios({
        method: "get",
        url: `/api/user/getCurrency`,
      });
    //   console.log(respData.data.result,'datadatadata')

      dispatch(setCurrencyOption(respData.data.result));
      return {
        status: "success",
        loading: false,
        result: respData.data.result,
      };
    } catch (err) {
      handleResp(err, "error");
      return {
        status: "failed",
        loading: false,
      };
    }
  };


  export const setCurrencyOption = (data) => {
    return {
      type: SET_CURRENCY,
      data,
    };
  };

  export const getPriceConversion = async (dispatch) => {
    try {
      let respData = await axios({
        method: "get",
        url: `/api/user/priceConversion`,
      });
      dispatch({
        type: SET_PRICE_CONVERSION,
        data: respData.data.result,
      });
      return true;
    } catch (err) {
      handleResp(err, "error");
      return false;
    }
  };


  export const DepositRequest = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/depositrequest`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors
        }
    }
}
  



// export const updateWallet = async (dispatch, data, callFrom) => {
//     if (callFrom == 'stake') {
//         dispatch({
//             type: UPDATE_USER_WALLET_STAKE,
//             data
//         })
//     }
//     return false

// }

// export const withdrawRequestFiat = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/fiatWithdraw`,
//             'data': data
//         });

//         getAssetData(dispatch);

//         if(respData && respData.data && respData.data.success == 'OTP'){
//             return {
//                 status: "OTP",
//                 loading: false,
//                 message: respData.data.message
//             }
//         }

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const cashFreeWithdrawReq = async (data,dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/cashFreeWithdraw`,
//             'data': data
//         });

//         getAssetData(dispatch);
        
//         if(respData && respData.data && respData.data.success == 'OTP'){
//             return {
//                 status: "OTP",
//                 loading: false,
//                 message: respData.data.message
//             }
//         }

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const payatMeWithdrawReq = async (data,dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/payatMeWithdraw`,
//             'data': data
//         });

//         getAssetData(dispatch);
        
//         if(respData && respData.data && respData.data.success == 'OTP'){
//             return {
//                 status: "OTP",
//                 loading: false,
//                 message: respData.data.message
//             }
//         }

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const fiatRequestVerify = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'patch',
//             'url': `/api/user/fiatWithdraw`,
//             'data': data
//         });
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//         }
//     }
// }

// // WITHDRAW REQUSET FUNCTION and by ADMIN APPROVE
// export const withdrawRequestCoin = async (data,dispatch) => { 
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/coinWithdraw`,
//             'data': data
//         });

//         getAssetData(dispatch);

//         if(respData && respData.data && respData.data.success == 'OTP'){
//             return {
//                 status : respData.data.success,
//                 message : respData.data.message
//             }
//         }

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// //Auto withdraw
// export const autoWithdrawRequestCoin = async (data,dispatch) => { 
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/coinAutoWithdraw`,
//             'data': data
//         });

//         getAssetData(dispatch);

//         if(respData && respData.data && respData.data.success == 'OTP'){
//             return {
//                 status : respData.data.success,
//                 message : respData.data.message
//             }
//         }

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }



// export const fundTransfer = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/user/fundTransfer`,
//             'data': data
//         });
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const getAssetByCurrency = async (currencyId) => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/user/getAsset/` + currencyId
//         });
//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//         }
//     }
// }

// export const setUserFirstCurrency = (assetData, dispatch) => {
//     dispatch({
//         type: SET_USER_FIRST_CURRENCY,
//         data: assetData
//     })
// }

// export const setUserSecondCurrency = (assetData, dispatch) => {
//     dispatch({
//         type: SET_USER_SECOND_CURRENCY,
//         data: assetData
//     })
// }

// export const getTrnxHistory = async (params, query) => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/user/history/transaction/` + params,
//             'params': query
//         });
//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//         }
//     }
// }