import React, { useEffect, useState } from "react";
import logo_dark from "../assets/images/logo_dark.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "./ThemeContext";


import { toastAlert } from "../lib/toastAlert";

export default function Footer() {
  //redux
  const { isAuth } = useSelector((state) => state.auth);

  const { theme } = useTheme();
  const siteDoc = useSelector((state) => state.siteSetting)

  const { isStake, isTrade } = useSelector((state) => state.account);

  const handleTrade = () => {
    if (!isTrade) {
      toastAlert("error", "Trade Access Blocked", "login");
    }
  };

  const handleStake = () => {
    if (!isStake) {
      toastAlert("error", "Stake Access Blocked", "login");
    }
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel_top">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <div className="left-footer-links">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer-links-card">
                      <h3>Instruments</h3>
                      <Link to="/cms/instruments-crypto">Crypto</Link>
                      <Link to="/cms/instruments-forex">Forex</Link>
                      <Link to="/cms/instruments-stocks">Stocks</Link>
                      <Link to="/cms/instruments-indices">Indices</Link>
                      <Link to="/cms/instruments-commodities">Commodities</Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer-links-card">
                      <h3>Trading</h3>
                      <Link to="/">Platform</Link>
                      {siteDoc?.walletAccess && <Link to={isAuth == true ? "/wallet" : "/login"}>Deposit </Link>}
                      <Link to={isAuth == true ? "/" : "/register"}>Open Account</Link>
                      <Link to={isAuth == true ? "/" : "/login"}>Sign In </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer-links-card">
                      <h3>Instruments</h3>
                      <Link to="/cms/platform-mt5">Mt5 Platform </Link>
                      <Link to="/cms/platform-webtrader">Webtrader Platform </Link>
                      <Link to="/cms/platform-mobile">Mobile Platform </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer-links-card">
                      <h3>FX Payments</h3>
                      <Link to="/account-types">Account Types</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="footer-about-card">
                <p className="footer_abt_content">Currency goes by </p>
                <Link className="navbar-brand" to="/">
                  {theme === "light" ? (
                    <img src={logo_dark} className="img-fluid" alt="Light logo" />
                  ) : (
                    <img src={logo_dark} className="img-fluid" alt="Dark logo" />
                  )}
                </Link>
                <p className="footer_abt_content"> "Galatraders" and Galatraders's family of trademarks are owned by Galatraders Corporation. All other trademarks appearing on this website are the property of their respective owners.</p>

              </div>
            </div>

          </div>
        </div>
        <div className="footer_panel_bottom">
          <p>
            Copyright © {new Date().getFullYear()} <a href="/">Galatraders Corporation</a>,
            All rights Reserved
          </p>
          <ul className="footer_menu_links">
            <li>
              <Link to="/terms">Terms of service</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
